import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";

import axios from "axios";
import { toast } from "react-toastify";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [lembrar, setLembrar] = useState(false);
  const [loading, setLoading] = useState(false);

  const esquecerLembrarSenha = useCallback((lembrarSenha, user, pass) => {
    if (!lembrarSenha) {
      localStorage.removeItem("dadosLogin");
    } else {
      const passBase64 = btoa(pass);
      localStorage.setItem(
        "dadosLogin",
        JSON.stringify({ username: user, password: passBase64 })
      );
    }

    setLembrar(lembrarSenha);
    console.log(lembrarSenha);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/usuario/logar`,
          {
            username,
            password,
          }
        );
        localStorage.setItem(
          "authUser",
          JSON.stringify(response.dados || response.data?.dados)
        );
        localStorage.setItem("lastLogin", Date.now());
        setTimeout(() => {
          window.location.href = "/principal";
        }, 200);
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.mensagem || "Ocorreu um erro ao fazer login."
        );
      } finally {
        setLoading(false);
      }
    },
    [username, password]
  );

  useEffect(() => {
    const dadosLogin = JSON.parse(localStorage.getItem("dadosLogin"));
    if (
      dadosLogin &&
      !lembrar &&
      dadosLogin.username !== "" &&
      dadosLogin.passBase64 !== ""
    ) {
      setUsername(dadosLogin.username);
      setPassword(atob(dadosLogin.password));
      setLembrar(true);
    }
  }, []);

  document.title = "Logar | Dark Store";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 card-bg-fill">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-secondary">Bem-vindo de volta!</h5>
                    </div>
                    <div className="p-2 mt-4">
                      <div className="mb-3">
                        <Label htmlFor="username" className="form-label">
                          Usuário
                        </Label>
                        <Input
                          className="form-control"
                          id="username"
                          placeholder="Digite o usuário"
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Esqueceu a senha?
                          </Link>
                        </div>
                        <Label className="form-label" htmlFor="password">
                          Senha
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Digite a senha"
                            id="password"
                          />

                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            checked={lembrar}
                            onChange={(e) =>
                              esquecerLembrarSenha(
                                e.target.checked,
                                username,
                                password
                              )
                            }
                            disabled={username === "" || password === ""}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Lembre-se de mim
                          </Label>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button
                          color="secondary"
                          disabled={loading}
                          className="btn btn-primary w-100"
                          onClick={(e) => handleSubmit(e)}
                        >
                          {loading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : null}
                          Entrar
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Login;
