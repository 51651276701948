import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalDownload = ({ modalDownload, setModalDownload, cliente }) => {
  const [loading, setLoading] = useState(false);
  const [urlOriginal, setUrlOriginal] = useState("");
  const [urlEpg, setUrlEpg] = useState("");
  const [urlEncurtada, setUrlEncurtada] = useState("");
  const [urlEncurtadaEpg, setUrlEncurtadaEpg] = useState("");
  const [tipo, setTipo] = useState("m3u8");

  const [dominios, setDominios] = useState([]);
  const [dominio, setDominio] = useState("");

  const alterarTipo = (tipo, cliente, dominio) => {
    if (tipo === "m3u8") {
      setUrlOriginal(
        `${dominio}get.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
      );
    } else if (tipo === "ts") {
      setUrlOriginal(
        `${dominio}get.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=ts`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=ts`
      );
    } else if (tipo === "ssiptv") {
      setUrlOriginal(
        `${dominio}ssiptv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
      );
    }
    setTipo(tipo);
  };

  const fetchData = useCallback(async (cliente) => {
    setLoading(true);
    if (dominios.length > 0 && dominio !== "") {
      alterarTipo("m3u8", cliente, dom.dominio);
      setLoading(false);
      return;
    }

    const response = await apiClient.get(`/url-lista`);
    if (response.sucesso) {
      setDominios(response.dados);
      if (response.dados.length > 0) {
        const dom = response.dados[0];
        setDominio(dom.dominio);
        alterarTipo("m3u8", cliente, dom.dominio);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  function validarURL(string) {
    const padrao = new RegExp(
      "^(https?:\\/\\/)?" + // protocolo
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // nome de domínio
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OU endereço ip (v4)
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // porta e caminho
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!padrao.test(string);
  }

  const encurtar = async (tipo) => {
    setLoading(true);
    if (!validarURL(urlOriginal)) {
      toast.error("URL inválida para encurtar.");
      setLoading(false);
      return;
    }

    const data = {
      url_original: tipo === "url" ? urlOriginal : urlEpg,
    };

    const response = await apiClient.post("/clientes/encurtar-url", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      if (tipo === "url") setUrlEncurtada(response.dados);
      else setUrlEncurtadaEpg(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDominios = async (cliente) => {
      await fetchData(cliente);
    };
    if (modalDownload && cliente) {
      fetchDominios(cliente);
    }
  }, [modalDownload, cliente]);

  return (
    <Modal
      isOpen={modalDownload}
      toggle={() => {
        setModalDownload(!modalDownload);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalDownload(!modalDownload);
        }}
      >
        URL do usuário {cliente?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="dominio" className="form-label">
                  URL da Lista
                </Label>
                <Input
                  type="select"
                  className="form-control"
                  id="dominio"
                  value={dominio}
                  onChange={(e) => {
                    setDominio(e.target.value);
                    setUrlOriginal(
                      `${e.target.value}get.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
                    );
                    setUrlEpg(
                      `${e.target.value}xmltv.php?username=${cliente?.externo_login}&password=${cliente?.externo_senha}&type=m3u_plus&output=m3u8`
                    );
                  }}
                  disabled={loading}
                >
                  {dominios.map((dom) => (
                    <option key={dom.id} value={dom.dominio}>
                      {dom.dominio}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="tipo" className="form-label">
                  Tipo de URL
                </Label>
                <div className="input-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tipo"
                      id="m3u8"
                      value="m3u8"
                      checked={tipo === "m3u8"}
                      onChange={() => {
                        alterarTipo("m3u8", cliente, dominio);
                      }}
                    />
                    <label className="form-check-label" htmlFor="m3u8">
                      M3U8
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tipo"
                      id="ts"
                      value="ts"
                      checked={tipo === "ts"}
                      onChange={() => {
                        alterarTipo("ts", cliente, dominio);
                      }}
                    />
                    <label className="form-check-label" htmlFor="ts">
                      TS
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tipo"
                      id="ssiptv"
                      value="ssiptv"
                      checked={tipo === "ssiptv"}
                      onChange={() => {
                        alterarTipo("ssiptv", cliente, dominio);
                      }}
                    />
                    <label className="form-check-label" htmlFor="ssiptv">
                      SSIPTV
                    </label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="url" className="form-label">
                  URL Original
                </Label>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    id="url"
                    value={urlOriginal || "Carregando..."}
                    onChange={() => {}}
                    disabled
                  />
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(urlOriginal);
                      toast.success("URL copiada com sucesso.");
                    }}
                    disabled={!urlOriginal || loading}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="urlEpg" className="form-label">
                  URL EPG
                </Label>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    id="urlEpg"
                    value={urlEpg || "Carregando..."}
                    onChange={() => {}}
                    disabled
                  />
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(urlEpg);
                      toast.success("URL copiada com sucesso.");
                    }}
                    disabled={!urlEpg || loading}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="urlEncurtada" className="form-label">
                  URL Encurtada
                </Label>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    id="urlEncurtada"
                    value={urlEncurtada}
                    onChange={() => {}}
                    disabled
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => encurtar("url")}
                    disabled={loading || urlEncurtada || !urlOriginal}
                  >
                    {loading ? (
                      <Spinner style={{ width: "12px", height: "12px" }} />
                    ) : (
                      "Encurtar"
                    )}
                  </button>
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(urlEncurtada);
                      toast.success("URL copiada com sucesso.");
                    }}
                    disabled={!urlEncurtada || loading}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="urlEncurtadaEpg" className="form-label">
                  URL EPG Encurtada
                </Label>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    id="urlEncurtadaEpg"
                    value={urlEncurtadaEpg}
                    onChange={() => {}}
                    disabled
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => encurtar("epg")}
                    disabled={loading || urlEncurtadaEpg || !urlEpg}
                  >
                    {loading ? (
                      <Spinner style={{ width: "12px", height: "12px" }} />
                    ) : (
                      "Encurtar"
                    )}
                  </button>
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(urlEncurtadaEpg);
                      toast.success("URL copiada com sucesso.");
                    }}
                    disabled={!urlEncurtadaEpg || loading}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDownload;
