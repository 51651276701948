import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalCompose = ({
  modal,
  toggle,
  loading,
  setLoading,
  setRefresh
}) => {
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");

  const enviarAviso = async () => {
    setLoading(true);

    const data = {
      id_destinatario: 0,
      assunto,
      mensagem,
    };

    const response = await apiClient.post("/avisos", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setAssunto("");
      setMensagem("");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="p-3 bg-light" toggle={toggle}>
        Novo Aviso de Painel
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="mb-3">
            <Input
              type="text"
              className="form-control"
              placeholder="Assunto"
              value={assunto}
              onChange={(e) => {
                setAssunto(e.target.value);
              }}
            />
          </div>
          <div className="ck-editor-reverse">
            <CKEditor
              editor={ClassicEditor}
              config={{
                language: "pt-br",
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
              }}
              onChange={(event, editor) => {
                setMensagem(editor.getData());
              }}
            />
          </div>
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          onClick={() => {
            toggle();
          }}
        >
          Descartar
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            enviarAviso();
          }}
          disabled={loading}
        >
          Enviar
        </button>
      </div>
    </Modal>
  );
};

export default ModalCompose;
