import React, { Fragment } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import ImportarLista from "./ImportarLista";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";

const Ferramentas = () => {
  document.title = "Ferramentas | Dark Store";

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Ferramentas" pageTitle="Utilitários" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Ferramentas Hz
                  </h4>
                  <small className="text-muted">
                    Ferramentas para facilitar o seu dia-a-dia
                  </small>
                </CardHeader>
                <CardBody>
                  <Row>
                    <ImportarLista />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Ferramentas;
