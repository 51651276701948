import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import Revendedores from "../pages/Revendedores";
import Clientes from "../pages/Clientes";
import ClientesOnline from "../pages/ClientesOnline";
import Avisos from "../pages/Avisos";
import Testes from "../pages/Testes";
import Tickets from "../pages/Tickets";
import Logs from "../pages/Logs";
import PedidosMercadoPago from "../pages/PedidosMercadoPago";
import Pedidos from "../pages/Pedidos";
import Downloads from "../pages/Downloads";
import Informacoes from "../pages/Informacoes";
import UtmDevices from "../pages/UtmDevices";
import Ferramentas from "../pages/Ferramentas";
import FerramentasAdmin from "../pages/FerramentasAdmin";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Solicitacoes from "../pages/Solicitacoes";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import { APIClient } from "../helpers/api_helper";
const apiClient = new APIClient();
const userData = apiClient.getLoggedinUser();

const authProtectedRoutes = [
  { path: "/principal", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/revendedores", component: <Revendedores /> },
  { path: "/avisos", component: <Avisos /> },
  { path: "/clientes", component: <Clientes /> },
  { path: "/clientes/:user_id", component: <Clientes /> },
  { path: "/clientes-online", component: <ClientesOnline /> },
  { path: "/testes", component: <Testes /> },
  { path: "/suporte", component: <Tickets /> },
  { path: "/logs", component: <Logs /> },
  { path: "/pedidos", component: <Pedidos /> },
  { path: "/pedidos-mercado-pago", component: <PedidosMercadoPago /> },
  { path: "/logs/:user_id", component: <Logs /> },
  { path: "/utm-devices", component: <UtmDevices /> },
  { path: "/downloads", component: <Downloads /> },
  { path: "/informacoes", component: <Informacoes /> },
  { path: "/ferramentas", component: <Ferramentas /> },
  {
    path: "/ferramentas-admin",
    component: userData?.data?.id === 1 ? <FerramentasAdmin /> : <Basic404 />,
  },
  { path: "/solicitacoes", component: <Solicitacoes /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/principal" />,
  },
  { path: "*", component: <Basic404 />},//<Navigate to="/principal" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
