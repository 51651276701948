import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIClient } from "../helpers/api_helper";
const apiClient = new APIClient();

const Navdata = () => {
  const history = useNavigate();
  const [userData, setUserData] = useState({});
  //state data
  const [isPrincipal, setIsPrincipal] = useState(false);
  const [isRevendas, setIsRevendas] = useState(false);
  const [isClientes, setIsClientes] = useState(false);
  const [isClientesOnline, setIsClientesOnline] = useState(false);
  const [isAvisos, setIsAvisos] = useState(false);
  const [isTestes, setIsTestes] = useState(false);
  const [isTickets, setIsTickets] = useState(false);
  const [isLogs, setIsLogs] = useState(false);
  const [isUtm, setIsUtm] = useState(false);
  const [isDownloads, setIsDownloads] = useState(false);
  const [isInformacoes, setIsInformacoes] = useState(false);
  const [isFerramentas, setIsFerramentas] = useState(false);
  const [isSolicitacoes, setIsSolicitacoes] = useState(false);
  const [isPedidoMP, setIsPedidoMP] = useState(false);

  const [isFerramentasAdmin, setIsFerramentasAdmin] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Principal");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Principal") {
      setIsPrincipal(false);
    }
    if (iscurrentState !== "Revendas") {
      setIsRevendas(false);
    }
    if (iscurrentState !== "Clientes") {
      setIsClientes(false);
    }
    if (iscurrentState !== "ClientesOnline") {
      setIsClientesOnline(false);
    }
    if (iscurrentState !== "Avisos") {
      setIsAvisos(false);
    }
    if (iscurrentState !== "Testes") {
      setIsTestes(false);
    }
    if (iscurrentState !== "Tickets") {
      setIsTickets(false);
    }
    if (iscurrentState !== "Logs") {
      setIsLogs(false);
    }
    if (iscurrentState !== "Utm") {
      setIsUtm(false);
    }
    if (iscurrentState !== "Downloads") {
      setIsDownloads(false);
    }
    if (iscurrentState !== "Informacoes") {
      setIsInformacoes(false);
    }
    if (iscurrentState !== "Ferramentas") {
      setIsFerramentas(false);
    }
    if (iscurrentState !== "Solicitacoes") {
      setIsSolicitacoes(false);
    }
    if (iscurrentState !== "FerramentasAdmin") {
      setIsFerramentasAdmin(false);
    }
    if (iscurrentState !== "PedidoMP") {
      setIsPedidoMP(false);
    }
    setUserData(apiClient.getLoggedinUser());
  }, [
    history,
    iscurrentState,
    isPrincipal,
    isRevendas,
    isClientes,
    isClientesOnline,
    isAvisos,
    isTestes,
    isTickets,
    isLogs,
    isUtm,
    isDownloads,
    isInformacoes,
    isFerramentas,
    isSolicitacoes,
    isFerramentasAdmin,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "principal",
      label: "Principal",
      icon: "ri-home-line",
      link: "/principal",
      stateVariables: isPrincipal,
      click: function (e) {
        e.preventDefault();
        setIsPrincipal(!isPrincipal);
        setIscurrentState("Principal");
        updateIconSidebar(e);
      },
    },
    {
      label: "Grupos",
      isHeader: true,
    },
    {
      id: "revendedores",
      label: "Revendedores",
      icon: "ri-account-circle-line",
      link: "/revendedores",
      click: function (e) {
        e.preventDefault();
        setIsRevendas(!isRevendas);
        setIscurrentState("Revendas");
        updateIconSidebar(e);
      },
      stateVariables: isRevendas,
    },
    {
      id: "clientes",
      label: "Clientes",
      icon: "ri-group-line",
      link: "/clientes",
      click: function (e) {
        e.preventDefault();
        setIsClientes(!isClientes);
        setIscurrentState("Clientes");
        updateIconSidebar(e);
      },
      stateVariables: isClientes,
    },
    {
      id: "testes",
      label: "Testes",
      icon: "ri-time-line",
      link: "/testes",
      click: function (e) {
        e.preventDefault();
        setIsTestes(!isTestes);
        setIscurrentState("Testes");
        updateIconSidebar(e);
      },
      stateVariables: isTestes,
    },
    {
      id: "utm-devices",
      label: "UTM Devices",
      icon: "ri-device-line",
      link: "/utm-devices",
      click: function (e) {
        e.preventDefault();
        setIsUtm(!isUtm);
        setIscurrentState("Utm");
        updateIconSidebar(e);
      },
      stateVariables: isUtm,
    },
    {
      label: "Financeiro",
      isHeader: true,
    },
    {
      id: "pedidos-mercado-pago",
      label: "Pedidos Mercado Pago",
      icon: "ri-money-dollar-circle-line",
      link: "/pedidos-mercado-pago",
      click: function (e) {
        e.preventDefault();
        setIsPedidoMP(!isPedidoMP);
        setIscurrentState("PedidoMP");
        updateIconSidebar(e);
      },
      stateVariables: isPedidoMP,
    },
    {
      label: "Suporte",
      isHeader: true,
    },
    {
      id: "suporte",
      label: "Suporte",
      icon: "ri-ticket-2-line",
      link: "/suporte",
      click: function (e) {
        e.preventDefault();
        setIsTickets(!isTickets);
        setIscurrentState("Tickets");
        updateIconSidebar(e);
      },
      stateVariables: isTickets,
    },
    {
      id: "solicitacoes",
      label: "Solicitações",
      icon: "ri-file-list-3-line",
      link: "/solicitacoes",
      click: function (e) {
        e.preventDefault();
        setIsSolicitacoes(!isSolicitacoes);
        setIscurrentState("Solicitacoes");
        updateIconSidebar(e);
      },
      stateVariables: isSolicitacoes,
    },
    {
      label: "Utilitários",
      isHeader: true,
    },
    {
      id: "clientes-online",
      label: "Clientes Online",
      icon: "ri-user-search-line",
      link: "/clientes-online",
      click: function (e) {
        e.preventDefault();
        setIsClientesOnline(!isClientesOnline);
        setIscurrentState("ClientesOnline");
        updateIconSidebar(e);
      },
      stateVariables: isClientesOnline,
    },
    {
      id: "ferramentas",
      label: "Ferramentas",
      icon: "ri-tools-line",
      link: "/ferramentas",
      click: function (e) {
        e.preventDefault();
        setIsFerramentas(!isFerramentas);
        setIscurrentState("Ferramentas");
        updateIconSidebar(e);
      },
      stateVariables: isFerramentas,
    },
    {
      id: "downloads",
      label: "Downloads",
      icon: "ri-download-2-line",
      link: "/downloads",
      click: function (e) {
        e.preventDefault();
        setIsDownloads(!isDownloads);
        setIscurrentState("Downloads");
        updateIconSidebar(e);
      },
      stateVariables: isDownloads,
    },
    {
      id: "informacoes",
      label: "Informações",
      icon: "ri-information-line",
      link: "/informacoes",
      click: function (e) {
        e.preventDefault();
        setIsInformacoes(!isInformacoes);
        setIscurrentState("Informacoes");
        updateIconSidebar(e);
      },
      stateVariables: isInformacoes,
    },
    {
      id: "logs",
      label: "Logs",
      icon: "ri-file-list-3-line",
      link: "/logs",
      click: function (e) {
        e.preventDefault();
        setIsLogs(!isLogs);
        setIscurrentState("Logs");
        updateIconSidebar(e);
      },
      stateVariables: isLogs,
    },
  ];

  const menuAdmin = [
    {
      label: "Administrativo",
      isHeader: true,
    },
    {
      id: "ferramentas-admin",
      label: "Ferramentas Admin",
      icon: "ri-tools-line",
      link: "/ferramentas-admin",
      click: function (e) {
        e.preventDefault();
        setIsFerramentas(!isFerramentasAdmin);
        setIscurrentState("FerramentasAdmin");
        updateIconSidebar(e);
      },
      stateVariables: isFerramentasAdmin,
    },
    {
      id: "avisos",
      label: "Avisos no Painel",
      icon: "ri-notification-3-line",
      link: "/avisos",
      click: function (e) {
        e.preventDefault();
        setIsAvisos(!isAvisos);
        setIscurrentState("Avisos");
        updateIconSidebar(e);
      },
      stateVariables: isAvisos,
    }
  ];

  return (
    <React.Fragment>
      {userData?.data?.id === 1 ? [...menuItems, ...menuAdmin] : menuItems}
    </React.Fragment>
  );
};
export default Navdata;
